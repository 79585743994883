<template>
  <div class="result">
    <div class="container">
      <div :class="score < 60 ? 'score score1' : 'score score2'">
        <span class="score_size">{{ score }}</span><span>分</span>
      </div>
      <div class="score_title">本次测评得分</div>
      <div class="desc">根据此次测评的成绩，您可以去试一试这套课程</div>

      <div class="imgBox">
        <!-- <img class="gg" src="../assets/img/gg@2x.png" alt="" @click="course()" /> -->
        <div v-html="wxHtml"></div>
      </div>
  
      <div class="desc">
        <div class="desc">
          <div class="identification"></div>
          <div class="desc_text">错读</div>
        </div>
        <div class="desc">
          <div class="identification2"></div>
          <div class="desc_text">漏读</div>
        </div>
      </div>

      <!-- 题型一 -->
      <div class="box">
        <div class="topic">{{ fristTitlte }}</div>
        <div class="containerW">
          <div class="firstwords_item" v-for="(item, index) in fristContent" :key="index">
            <div :class="tem.dp_message == 16
                ? 'firstwords_size2'
                : tem.shen != 0
                  ? 'firfirstwords_size3'
                  : tem.yun != 0
                    ? 'firfirstwords_size3'
                    : 'firfirstwords_size'
              " v-for="(tem, ndex) in item" :key="ndex">
              {{ tem.size }}
            </div>
          </div>
        </div>
        <!-- 题型二 -->
        <div class="topic">{{ secondTitlte }}</div>
        <div class="containerW">
          <div class="" v-for="(item, index) in secondContent.data" :key="index">
            <div :class="item.dp_message == 16
                ? 'firstwords_size2'
                : item.shen != 0
                  ? 'firfirstwords_size3'
                  : item.yun != 0
                    ? 'firfirstwords_size3'
                    : 'firfirstwords_size'
              ">
              {{ item.size }}
            </div>
          </div>
        </div>
        <!-- 题型三 -->
        <div class="topic3">{{ thirdTitlte }}</div>
        <div class="audio">
          <div class="option">
            <img class="round" @click="vicoePlay" src="../assets/img/play2.png" alt="" v-show="vicoeShow" />
            <img class="round" @click="vicoePlay" src="../assets/img/zt@2x.png" alt="" v-show="vicoeShow == false" />
          </div>
          <div class="audio_box">
            <img class="vicoe" src="../assets/img/listen.png" alt="" v-show="vicoeShow" />
            <img class="vicoe" src="../assets/img/listen2.gif" alt=" " v-show="vicoeShow == false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import wx from "weixin-js-sdk";
import sha1 from "sha1";

import { myfun } from "../assets/js/tool";
export default {
  name: "Result",
  data() {
    return {
      list: "",
      score: 60,
      fristTitlte: "",
      secondTitlte: "",
      thirdTitlte: "",
      fristContent: [],
      secondContent: [],
      thirdContent: [],
      vicoeShow: true,
      wxHtml: "",
      localId: "",
    };
  },

  mounted() {
    // this.getHtml();
    this.getTicket();
    this.evaluation();
    var frist = this.$route.params.frist;
    var second = this.$route.params.second;
    var third = this.$route.params.third;

    // 获取题目名
    this.fristTitlte = frist.title;
    this.secondTitlte = second.title;
    this.thirdTitlte = third.title;
    // 获取展示内容

    var one = myfun(frist.content, 1);
    this.segment(one.data);
    this.secondContent = myfun(second.content, 1);
    this.localId = third.localId;
    //   alert(1);

    // 获取分数
    this.score = parseInt(
      (third.score + one.score + this.secondContent.score) / 3
    );
  },
  methods: {
    // 播放录音
    vicoePlay() {
      // var that = this;
      this.vicoeShow = !this.vicoeShow;
      if (this.vicoeShow) {
        wx.stopVoice({
          localId: this.localId, // 需要播放的音频的本地ID，由stopRecord接口获得
        });
      } else {
        wx.playVoice({
          localId: this.localId, // 需要播放的音频的本地ID，由stopRecord接口获得
        });
      }
    },

    // 分段
    segment(i) {
      var reg = /[^\u4E00-\u9FA5]/;
      var arr = [];
      i.forEach((e) => {
        if (reg.test(e.size)) {
          this.fristContent.push(arr);
          arr = [];
        } else {
          arr.push(e);
        }
      });
    },
    // 记录完成测评人数埋点
    evaluation() {
      axios
        .get("/api/edu/caiHongKeyOrderInfo/Evaluation")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 记录点开课程链接人数埋点
    course() {
      axios
        .get("/api/edu/caiHongKeyOrderInfo/course")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 微信初始化
    // 获得jsapi_ticket
    getTicket() {
      var that = this;
      axios
        .get("/api/edu/caiHongKeyOrderInfo/myWx")
        .then((res) => {
          // alert(res.data.data.ticket);
          that.startInit(res.data.data.ticket);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 通过config接口注入权限验证配置
    startInit(i) {
      var that = this;
      var not = Math.round(new Date().getTime() / 1000).toString();
      var u = navigator.userAgent;
      var url;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        url = this.$route.params.url;
      } else {
        url = window.location.href;
      }
      var aa =
        "jsapi_ticket=" +
        i +
        "&noncestr=" +
        not +
        "&timestamp=" +
        not +
        "&url=" +
        url;
      var signature = sha1(aa);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx68e7c88b82e0b02b", //"wx2303d161ca693dd5", // 必填，公众号的唯一标识
        timestamp: not, // 必填，生成签名的时间戳
        nonceStr: not, // 必填，生成签名的随机串
        signature: signature, // 必填，签名
        jsApiList: ["playVoice", "stopVoice", "onVoicePlayEnd"], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"], // 需要使用的开放标签列表
      });
      wx.ready(function () {
        wx.onVoicePlayEnd({
          success: function (res) {
            console.log(res.localId);
            that.vicoePlay()
          },
        });
      });
      this.getHtml();
    },
    getHtml() {
      let script = document.createElement("script");
      script.type = "text/wxtag-template";
      script.text = `<button class="btn blue mt1" style="height: 94px;width: 315px; opacity: 0;">跳转小程序</button>`;
      //    script.text =  ` <img  style="height: 94px;width: 315px;" src="../assets/img/gg@2x.png" alt=""  />`
      this.wxHtml = `<wx-open-launch-weapp style="height: 94px;width: 315px; position: absolute;top: 0; left: 0; opacity: 0;"
                                  id="launch-btn"
                                    appid="wx74848b968f683716"
                                    username="gh_dc1d49bbbf48"
                                    path="pages/search/list?key=搜索课程&searchName=普通话&keyName=普通话&searchObj=1&type=payafter"
                                  >${script.outerHTML}
                                </wx-open-launch-weapp>`;
      this.$nextTick(() => {
        let launchButton = document.getElementById("launch-btn");
        launchButton.addEventListener("launch", function (e) {
          console.log("success", e);
        });

        launchButton.addEventListener("error", function (e) {
          console.log("fail", e);
        });
      });
    },
  },
};
</script>
<style scoped>
.result {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px 15px 0;
  background: #fafafa;
}

.container {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 9px;
  padding: 10px 15px 0;
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.09);
  text-align: left;
  overflow-y: scroll;
}

.score1 {
  background: url("../assets/img/fscd@2x (1).png");
}

.score2 {
  background: url("../assets/img/fscd@2x.png");
}

.score {
  width: 260px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #ff6631;
  line-height: 50px;
}

.score_size {
  font-size: 40px;
}

.score_title {
  font-size: 18px;
  font-weight: 400;
  color: #210600;
  line-height: 30px;
  text-align: center;
}

.desc {
  font-size: 12px;
  line-height: 40px;
  font-weight: 400;
  color: #210600;
  text-align: left;
}

.imgBox {
  width: 315px;
  height: 94px;
  position: relative;
   background-image: url('../assets/img/gg@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
   
}

/* .gg {
  width: 315px;
  height: 94px;
  position: absolute;
  top: 0;
  left: 0;
  
} */

.topic {
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  color: #210600;
  margin-bottom: 10px;
  margin-top: 12px;
}

.topic3 {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: #210600;
  margin: 40px 0 20px;
}

/*第一题 */
.containerW {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.firstwords_item {
  width: 30%;
  padding: 10px 13.5px;
  background: #f7f5f5;
  margin: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.firfirstwords_size {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #210600;
}

.firstwords_size2 {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: rgba(177, 169, 168, 1);
}

.firfirstwords_size3 {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: rgba(253, 113, 84, 1);
}

/* 第三题 */
.audio {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio_box {
  width: 80%;
  height: 50px;
}

.vicoe {
  width: 100%;
  height: 100%;
}

.round {
  width: 50px;
  height: 50px;
}

.desc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.identification {
  width: 15px;
  height: 15px;
  background-color: rgba(253, 113, 84, 1);
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.identification2 {
  width: 15px;
  height: 15px;
  background-color: rgba(177, 169, 168, 1);
  border-radius: 50%;
  margin: 0 5px 0 15px;
}

.desc_text {
  font-size: 12px;
  font-weight: 400;
  color: #210600;
}
</style>
