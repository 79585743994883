var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"result"},[_c('div',{staticClass:"container"},[_c('div',{class:_vm.score < 60 ? 'score score1' : 'score score2'},[_c('span',{staticClass:"score_size"},[_vm._v(_vm._s(_vm.score))]),_c('span',[_vm._v("分")])]),_c('div',{staticClass:"score_title"},[_vm._v("本次测评得分")]),_c('div',{staticClass:"desc"},[_vm._v("根据此次测评的成绩，您可以去试一试这套课程")]),_c('div',{staticClass:"imgBox"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.wxHtml)}})]),_vm._m(0),_c('div',{staticClass:"box"},[_c('div',{staticClass:"topic"},[_vm._v(_vm._s(_vm.fristTitlte))]),_c('div',{staticClass:"containerW"},_vm._l((_vm.fristContent),function(item,index){return _c('div',{key:index,staticClass:"firstwords_item"},_vm._l((item),function(tem,ndex){return _c('div',{key:ndex,class:tem.dp_message == 16
              ? 'firstwords_size2'
              : tem.shen != 0
                ? 'firfirstwords_size3'
                : tem.yun != 0
                  ? 'firfirstwords_size3'
                  : 'firfirstwords_size'},[_vm._v(" "+_vm._s(tem.size)+" ")])}),0)}),0),_c('div',{staticClass:"topic"},[_vm._v(_vm._s(_vm.secondTitlte))]),_c('div',{staticClass:"containerW"},_vm._l((_vm.secondContent.data),function(item,index){return _c('div',{key:index},[_c('div',{class:item.dp_message == 16
              ? 'firstwords_size2'
              : item.shen != 0
                ? 'firfirstwords_size3'
                : item.yun != 0
                  ? 'firfirstwords_size3'
                  : 'firfirstwords_size'},[_vm._v(" "+_vm._s(item.size)+" ")])])}),0),_c('div',{staticClass:"topic3"},[_vm._v(_vm._s(_vm.thirdTitlte))]),_c('div',{staticClass:"audio"},[_c('div',{staticClass:"option"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.vicoeShow),expression:"vicoeShow"}],staticClass:"round",attrs:{"src":require("../assets/img/play2.png"),"alt":""},on:{"click":_vm.vicoePlay}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.vicoeShow == false),expression:"vicoeShow == false"}],staticClass:"round",attrs:{"src":require("../assets/img/zt@2x.png"),"alt":""},on:{"click":_vm.vicoePlay}})]),_c('div',{staticClass:"audio_box"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.vicoeShow),expression:"vicoeShow"}],staticClass:"vicoe",attrs:{"src":require("../assets/img/listen.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.vicoeShow == false),expression:"vicoeShow == false"}],staticClass:"vicoe",attrs:{"src":require("../assets/img/listen2.gif"),"alt":" "}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('div',{staticClass:"desc"},[_c('div',{staticClass:"identification"}),_c('div',{staticClass:"desc_text"},[_vm._v("错读")])]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"identification2"}),_c('div',{staticClass:"desc_text"},[_vm._v("漏读")])])])
}]

export { render, staticRenderFns }