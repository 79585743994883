function myfun(i, ispin) {
 
    var data = JSON.parse(i);
  
    //   console.log("data=" + JSON.stringify(data));
 
  var arr = data.xml_result.read_sentence.rec_paper.read_sentence;
 console.log(arr);
  var content = arr.content;
  var fluency_score = parseInt(arr.fluency_score); //流畅度分
  var integrity_score = parseInt(arr.integrity_score); //完整度分
  var phone_score = parseInt(arr.phone_score); //声韵分
  var tone_score = parseInt(arr.tone_score); //调型分
  var totalScore = parseInt(
    fluency_score * 0.4 +
      integrity_score * 0.4 +
      phone_score * 0.1 +
      tone_score * 0.1
  ); //读自定义分的总分：读自定义分 = 流畅度*0.4 + 完整度*0.4 + 声韵*0.1 + 调型*0.1
  var sentence = arr.sentence;
  var array = [];
  if (sentence.constructor == Array) {
    // console.log("数组");
    for (let i = 0; i < sentence.length; i++) {
      var array1 = [];
      if (sentence[i].word.constructor == Array) {
        // console.log("数组2");
        for (let j = 0; j < sentence[i].word.length; j++) {
          if (sentence[i].word[j].syll.constructor == Array) {
            for (let g = 0; g < sentence[i].word[j].syll.length; g++) {
              if (sentence[i].word[j].syll[g].phone.length == 2) {
                sentence[i].word[j].syll = sentence[i].word[j].syll[g];
              }
            }
          }
          var obj = {
            size: sentence[i].word[j].syll["content"],
            dp_message: sentence[i].word[j].syll["dp_message"],
            shen: sentence[i].word[j].syll.phone[0]["perr_msg"],
            yun: sentence[i].word[j].syll.phone[1]["perr_msg"],
          };
          array1.push(obj);
        }
      } else {
        // console.log("不是数组2");
        if (sentence[i].word.syll.constructor == Array) {
          for (let g = 0; g < sentence[i].word.syll.length; g++) {
            if (sentence[i].word.syll[g].phone.length == 2) {
              sentence[i].word.syll = sentence[i].word.syll[g];
            }
          }
        }
        var obj1 = {
          size: sentence[i].word.syll["content"],
          dp_message: sentence[i].word.syll["dp_message"],
          shen: sentence[i].word.syll.phone[0]["perr_msg"],
          yun: sentence[i].word.syll.phone[1]["perr_msg"],
        };
        array1.push(obj1);
      }
      array.push(array1);
    }
  } else {
    // console.log("不是数组");
    var str = [];
    if (sentence.word.constructor == Array) {
      for (let j = 0; j < sentence.word.length; j++) {
        if (sentence.word[j].syll.constructor == Array) {
          if (sentence.word[j].syll.constructor == Array) {
            sentence.word[j].syll = sentence.word[j].syll[1];
          }
        }
        var obj4 = {
          size: sentence.word[j].syll["content"],
          dp_message: sentence.word[j].syll["dp_message"],
          shen: sentence.word[j].syll.phone[0]["perr_msg"],
          yun: sentence.word[j].syll.phone[1]["perr_msg"],
        };
        str.push(obj4);
      }
    } else {
      var obj5 = {
        size: sentence.word.syll["content"],
        dp_message: sentence.word.syll["dp_message"],
        shen: sentence.word.syll.phone[0]["perr_msg"],
        yun: sentence.word.syll.phone[1]["perr_msg"],
      };
      str.push(obj5);
    }
    array.push(str);
  }

  if (ispin == 0) {
    //有拼音
    var str1 = {
      data: second(array, content),
      score: totalScore,
    };

    return str1;
  } else {
    var str2 = {
      data: frist(array, content),
      score: totalScore,
    };
    return str2;
  }
}
//无拼音
function frist(array, evaluation) {
  var obj = [];
  for (let i = 0; i < array.length; i++) {
    obj = obj.concat(array[i]);
  }
  var evaluation1 = evaluation;
  for (let i = 0; i < evaluation1.length; i++) {
    var reg = /[^\u4E00-\u9FA5]/;
    if (reg.test(evaluation1[i])) {
      var str = {
        size: evaluation1[i],
        dp_message: "0",
        shen: "0",
        yun: "0",
      };
      obj.splice(i, 0, str);
    }
  }
  // console.log(JSON.stringify(obj));
  return obj;
}
function second(array, evaluation) {
  var nor = [];
  for (let i = 0; i < array.length; i++) {
    nor = nor.concat(array[i]);
  }
  var nor1 = [];
  for (let i = 0; i < evaluation.length; i++) {
    nor1.push(evaluation[i].size.join(""));
  }
  var nor2 = nor1.join("");
  for (let i = 0; i < nor2.length; i++) {
    var reg = /[^\u4E00-\u9FA5]/;
    if (reg.test(nor2[i])) {
      var str = {
        size: nor2[i],
        dp_message: "0",
        shen: "0",
        yun: "0",
      };
      nor.splice(i, 0, str);
    }
  }

  var pinglist = [];
  for (let i = 0; i < evaluation.length; i++) {
    pinglist.push(evaluation[i].arr);
  }

  var sizelist = [];
  for (let i = 0; i < pinglist.length; i++) {
    sizelist = sizelist.concat(pinglist[i]);
  }

  for (let i = 0; i < nor.length; i++) {
    nor[i]["pin"] = sizelist[i]["pin"];
  }
}


export {
  //很关键
  myfun,
  frist,
  second,
 
};
